import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import FurtherReading from "../components/furtherReading"

const InfographicsForSEO = () => (
  <Layout>
    <SEO
      title="Infographics for SEO"
      description="5 reasons why you should be using infographics. Easier backlinks, content that shares itself, promote your business"
    />
    <h1>Infographics for SEO</h1>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/pNhw2GQ.png"
        alt="5 reasons infographics should be part of your marketing strategy"
      />
    </div>

    <h2>Why You Should Use Infographics as Part of Your SEO and Marketing Strategy</h2>
    <p>
    Search engine optimisation is an important part of your marketing strategy. When done right, it can help you reduce expenses because you are able to pull in more leads without spending on ads.
    </p>
    <p>SEO is easy in theory but anyone that has any experience in it, knows it can take a lot of time and it’s hard to tell if something is actually working or not. This is what causes a split in opinion of all the different techniques.</p>
    <p>There’s technical SEO that requires you do things on your site that google likes like produce good content and use proper formatting with HTML tags. Then there’s another side of SEO where you create authority by getting other high quality sites in your niche to link to your site. We call these backlinks.</p>
    <p>High quality backlinks are one of the most difficult things in SEO to get right. Lucky for you, this article is all about one of the best ways to get them.</p>
    <p>Here’s 5 reasons why you should be using infographics.</p>

    <h2>It's Much Easier to Get Accepted For Backlinks</h2>
    <p>When you write a piece of content, the actual text might be unique but you can imagine, there will be lots of similar pieces of content on the web already. Google has seen the other content and ranked it appropriately. Not only that, other people who are in your niche will have likely seen this content too. As special as it is to you, if it isn’t ground breaking in your field, it won’t mean as much to anyone else.</p>
    <p>That means, when you reach out to get featured on their site. They will check your link and wonder why they should use your content over someone else’s. in this case, they will either ignore you or ask you for payment.</p>
    <p>Infographics are content that webmasters will actually want to share and your rate of getting accepted for a backlink will be significantly higher. They are unique and other people will be able to see it at first glance instead of taking 20 minutes to read your content.</p>

    <h2>Images Are More Easily Digested</h2>
    <p>I don’t need to say much about this one, we all know the adage; a picture is worth a thousand words. Instead I’ll talk about the benefits that come from this.</p>
    <p>Attention spans are so short on the internet because information is in abundance. It means that if you don’t get to the point quickly with your audience, they will leave and go somewhere else.</p>
    <p>Infographics might give an equal amount of content in time as a written article but it can take much less effort to get through if done right. It can even be entertaining and any lessons or surprises are much easier to see at a glance.</p>

    <h2>Cross Over Into Real Life</h2>
    <p>You might have spent a long time creating content that your customers love and you want to experiment with creating something that you can print in real life. You could make a brochure or some flyers and condense your best content so you can hand it out or have in your office.</p>
    <p>Some people love reading but they are probably the ones that are already familiar with your brand and trust you. They will consume everything you put out anyway. Sometimes, they are not the ones you want to go after.</p>
    <p>Infographics don’t need repurposing. Not as much as text based media anyway. You can often get a graphic designer to make some simple edits to fit a standard paper size then go ahead and print. If you are lucky, the page will already be print ready and you can save a lot of time.</p>

    <h2>They Share Themselves</h2>
    <p>Infographics are much harder to copy than a traditional text article. A person looking for content for their site can search the top Google results for their keyword and take your content. They will reword it and make improvements if they are smart. Then they will have some great content at your expense.</p>
    <p>With an infographic, the far easier option would be to use your infographic with their content. There are a few ways to do this and it depends on the legitimacy of the webmaster or sharer.</p>
    <p>The unlikely scenario is that they could just take your work with no credit. This wouldn’t be too smart because the infographic would be unique to you and will have your logo & website on it.</p>
    <p>More likely is that they either take it and credit you without asking, in which case you get your backlink. Or they will ask for your permission to use the image on their site. You reply with yes, please credit me with a “dofollow” link.</p>

    <h2>They Help Sell Your Business</h2>
    <p>The most important reason by far. Ideally, an infographic should be created with the main goal of self promotion. You can use them to simply inform about your field but a much more effective use of your time and resources would be for marketing.</p>
    <p>Selecting the right information for your infographic will help paint your business in the best possible light. You can find statistics that help support your ideas, just like you would for a written article. Use your imagination to come up with some ideas for content you can add to your infographic that will help you.</p>
    <p>After the majority of the sharing of your work is done, it will have seen thousands, if not millions of eyes. You can’t change it once it out there so you want to send the best message.</p>

    <h2>What Next?</h2>
    <p>Now you know <i>why</i> you should be using infographics, it’s time to find out what exactly should go into one to get the best results. Infographics are nothing new and there so many examples. You could probably even find some in your own niche if you search Pinterest.</p>
    <p>The problem is that some of these are good but others are bad and it might not be easy to tell at first glance, if you don’t know what you are looking for.</p>
    <p>Read our <Link to="/infographic-design/" style={{ textDecoration: "none" }}>
    tips on infographic design
    </Link> to find out what you should include in yours to avoid wasting time and effort on something that was doomed from the start.</p>


    {/* <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link> */}


    <Link to="/infographics/" style={{ textDecoration: "none" }}>
      Go back to the infographics homepage
    </Link>
  </Layout>
)

export default InfographicsForSEO
